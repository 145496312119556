import React from "react";
import InsertChartOutlinedIcon from "@material-ui/icons/InsertChartOutlined";
import { Button, Checkbox, Drawer } from "@material-ui/core";
import ResetIco from "../assets/images/reset.svg";
import { useState } from "react";
import { useEffect } from "react";
import { Alert } from "@material-ui/lab";

export const FilterDrawer = ({
  open,
  handleDrawerClose,
  title,
  items,
  onApply,
  selectedValues = [],
  displayName = "name",
}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setSelectedItems([...selectedValues]);
  }, [selectedValues, open]);

  const onSelect = (id) => {
    let tempItems = [...selectedItems];
    tempItems.includes(id)
      ? tempItems.splice(tempItems.indexOf(id), 1)
      : tempItems.push(id);

    setSelectedItems(tempItems);
  };

  const onAllSelect = () =>
    setSelectedItems(
      selectedItems.length === items.length ? [] : items.map((x) => x.id)
    );

  return (
    <Drawer
      key={open}
      anchor="left"
      open={open}
      onClose={() => handleDrawerClose()}
    >
      <div className="drawer-content">
        <h2>
          <InsertChartOutlinedIcon /> {title}
        </h2>
        <div className="buiding-res">
          <h4>{`Select ${title} you want to view analytics`}</h4>
        </div>
        <div className="buiding-res">
          <h2>{title}</h2>
          <div>
            {items.length > 0 ? (
              <span>
                <Checkbox
                  key={"all"}
                  name={"all"}
                  color="primary"
                  checked={selectedItems.length === items.length}
                  onChange={onAllSelect}
                />
                All
              </span>
            ) : (
              <></>
            )}
            {items.length > 0 &&
              items.map((item, index) => {
                return (
                  <span key={index}>
                    <Checkbox
                      key={item.id}
                      name={item.id}
                      color="primary"
                      checked={selectedItems.some((x) => x === item.id)}
                      onChange={() => onSelect(item.id)}
                    />
                    {item[displayName] ? item[displayName] : ""}
                  </span>
                );
              })}
          </div>
        </div>
        {selectedItems.length === 0 && (
          <Alert style={{ width: "auto" }} severity="error">
            Please select atleast one {title}
          </Alert>
        )}

        <div className="button-wrapper">
          <Button
            onClick={() => setSelectedItems([])}
            variant="outlined"
            disableElevation
            size="medium"
          >
            <img className="mr-5" src={ResetIco} /> Reset
          </Button>
          <Button
            onClick={() => onApply(selectedItems) || handleDrawerClose()}
            variant="contained"
            color="primary"
            disabled={selectedItems.length === 0}
            disableElevation
            size="medium"
          >
            Apply
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
