import React from "react";
import { useStyles } from "../styles";
import { Chip } from "@material-ui/core";
import { useProject } from "../../../hooks/useProject";

export const AssetTypeChips = ({ show }) => {
  const classes = useStyles();
  const { selectedSubAssetTypes, setSelectedSubAssetTypes, subAssetTypes } =
    useProject();

  const handleRemoveselectedSubAssetTypes = (removedId) => {
    const newselectedSubAssetTypes = selectedSubAssetTypes.filter(
      (id) => id !== removedId
    );
    setSelectedSubAssetTypes(newselectedSubAssetTypes);
  };

  return show ? (
    <div className="p-name">
      <div className={classes.chipsSection}>
        {selectedSubAssetTypes &&
          subAssetTypes
            ?.filter((x) => selectedSubAssetTypes.includes(x.id))
            .map((subAssetType, index) => (
              <Chip
                key={index}
                label={subAssetType.subAssetType}
                size="small"
                color="primary"
                variant="outlined"
                onDelete={() =>
                  handleRemoveselectedSubAssetTypes(subAssetType.id)
                }
              />
            ))}
      </div>
    </div>
  ) : null;
};
