import { ProjectEndPoints } from "../../../config";
import axios from "../../../utils/axios";

const getProjects = async (queryParams) => {
  try {
    const response = await axios.get(ProjectEndPoints.projects, {
      params: queryParams,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getProjectsPaginated = async (data) => {
  try {
    const response = await axios.post(ProjectEndPoints.projectsPaginated, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const uploadProjectCsvFile = async (projectDetails, file) => {
  try {
    let url = ProjectEndPoints.csvUpload;
    url = url.replace("{projectName}", projectDetails.projectName);
    url = url.replace("{clientId}", projectDetails.clientId);
    url = url.replace("{categoryId}", projectDetails.categoryId);
    url = url.replace("{schemaId}", projectDetails.schemaId);
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(url, formData);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw error.response.data;
    }
    throw error;
  }
};

const getPenetrations = async (data) => {
  try {
    let url = ProjectEndPoints.assetData;

    const response = await axios.post(url, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getAllAssetTypes = async () => {
  try {
    const response = await axios.get(ProjectEndPoints.assetTypes);
    return response;
  } catch (error) {
    throw error;
  }
};

const getProjectAssetTypes = async (projectId, categoryId) => {
  try {
    const response = await axios.get(
      ProjectEndPoints.projectAssetTypes + "/" + projectId + "/" + categoryId
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getSubAssetTypes = async (assetTypeId, projectId) => {
  try {
    const response = await axios.get(
      ProjectEndPoints.subAssetTypes + "/" + assetTypeId + "/" + projectId
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const isUniqueAssetId = async (assetId) => {
  try {
    let url = ProjectEndPoints.isUniqueAssetId;
    url = url.replace("{assetId}", assetId);
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

const getBuildings = async (queryParams) => {
  try {
    const response = await axios.get(ProjectEndPoints.buildings, {
      params: queryParams,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getAllCategories = async () => {
  try {
    const response = await axios.get(ProjectEndPoints.categories);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getAllSchemas = async () => {
  try {
    const response = await axios.get(ProjectEndPoints.schema);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getProjectAssetCategories = async (projectId) => {
  try {
    let url = ProjectEndPoints.projectCategories;
    url = url.replace("{projectId}", projectId);
    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default {
  getProjects,
  getProjectsPaginated,
  uploadProjectCsvFile,
  getPenetrations,
  getAllAssetTypes,
  getProjectAssetTypes,
  isUniqueAssetId,
  getBuildings,
  getAllCategories,
  getProjectAssetCategories,
  getAllSchemas,
  getSubAssetTypes,
};
