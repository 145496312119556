import { memo } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  InputLabel,
} from "@material-ui/core";
import { getErrorMessage } from "../../utils/formUtils";

const getOptionsByDataSource = (dataSource, resources) => {
  return resources?.[dataSource]?.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.key}
    </MenuItem>
  ));
};

const StringInput = ({ field, control, error }) => {
  return (
    <Controller
      name={field.key}
      control={control}
      rules={field.validations}
      render={({ onChange, value }) => (
        <TextField
          size="small"
          error={Boolean(error)}
          helperText={Boolean(error) && error}
          onChange={onChange}
          value={value}
          type={field.dataType}
          variant="outlined"
          label={field.label}
          disabled={field?.form?.disabled}
          style={{ width: "100%" }}
        />
      )}
    />
  );
};

const FormSelect = ({ field, resources, control, error }) => (
  <Controller
    name={field.key}
    control={control}
    rules={field.validations}
    render={({ onChange, value }) => (
      <FormControl size="small" fullWidth>
        <InputLabel style={{ marginLeft: "13px", marginTop: "-6px" }}>
          {field.label}
        </InputLabel>
        <Select
          size="small"
          style={{ textAlign: "left" }}
          variant="outlined"
          error={Boolean(error)}
          helperText={Boolean(error) && error}
          value={value}
          onChange={onChange}
          label={field.label}
          disabled={field?.form?.disabled}
        >
          {field?.form?.dataSource
            ? getOptionsByDataSource(field?.form?.dataSource, resources)
            : field?.form?.options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.key}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    )}
  />
);

const FormField = ({ field, userData, resources }) => {
  //This context data from react-form-hook
  const {
    control,
    formState: { errors },
  } = useFormContext();

  switch (field.dataType) {
    case "string":
    case "number":
      return field.form?.dataSource || field?.form?.options?.length ? (
        <FormSelect
          field={field}
          resources={resources}
          control={control}
          error={getErrorMessage(errors?.[field?.key]?.type)}
        />
      ) : (
        <StringInput
          field={field}
          userData={userData}
          control={control}
          error={getErrorMessage(errors?.[field?.key]?.type)}
        />
      );

    default:
      return <></>;
  }
};

export default memo(FormField);
