import axios from "axios";
import { BASE_API } from "../config";

// ----------------------------------------------------------------------

const axiosServices = axios.create({
  baseURL: BASE_API,
  withCredentials: true,
});

const tempAxiosServices = axios.create({
  baseURL: BASE_API,
  withCredentials: true,
});

axiosServices.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      return await retryMainRequest(error);
    }
    return Promise.reject(
      (error?.response && error.response?.data) || "Wrong Services"
    );
  }
);

const retryMainRequest = async (error) => {
  try {
    const refresh = await tempAxiosServices.post(
      `${BASE_API}/auth/refresh-token`
    );
    const config = error.response.config;

    if (refresh?.status === 200) {
      try {
        const mainRequest = await axiosServices({
          method: config.method,
          url: `${BASE_API}${config.url}`,
          data: config.data ? JSON.parse(config.data) : null,
        });
        return Promise.resolve(mainRequest);
      } catch (err) {
        return Promise.reject(
          (error.response && error.response.data) || "Wrong Services"
        );
      }
    } else {
      await axiosServices.get("/auth/logout");
      window.location.href = "/login";
      return;
    }
  } catch (err) {
    if (
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/forgot-password" &&
      window.location.pathname !== "/reset-password"
    ) {
      await axiosServices.get("/auth/logout");
      window.location.href = "/login";
    }
  }
};

// ----------------------------------------------------------------------

export default axiosServices;
