import { memo } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  FormHelperText,
  Checkbox,
} from "@material-ui/core";
import { getErrorMessage } from "../../utils/formUtils";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { Label } from "@material-ui/icons";

const getOptionsByDataSource = (dataSource, resources) => {
  return resources?.[dataSource]?.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.key}
    </MenuItem>
  ));
};

const StringInput = ({ field, control, error }) => {
  return (
    <Controller
      name={field.key}
      control={control}
      rules={field.validations}
      render={({ onChange, value }) => (
        <>
          <InputLabel size="small" style={{ marginBottom: "5px" }}>
            {field.label}
          </InputLabel>
          <TextField
            size="small"
            error={Boolean(error)}
            helperText={Boolean(error) && error}
            onChange={onChange}
            value={value}
            variant="outlined"
            style={{ width: "100%" }}
          />
        </>
      )}
    />
  );
};

const DateInput = ({ field, control, error }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        name={field.key}
        control={control}
        rules={field.validations}
        render={({ onChange, value }) => (
          <>
            <InputLabel size="small" style={{ marginBottom: "5px" }}>
              {field.label}
            </InputLabel>
            <TextField
              size="small"
              error={Boolean(error)}
              helperText={Boolean(error) && error}
              onChange={onChange}
              value={value}
              variant="outlined"
              style={{ width: "100%" }}
              type="date"
            />
          </>
        )}
      />
    </MuiPickersUtilsProvider>
  );
};

const FormSelect = ({ field, control, error }) => (
  <Controller
    name={field.key}
    control={control}
    rules={field.validations}
    render={({ onChange, value }) => (
      <>
        <InputLabel style={{ marginBottom: "5px" }}>{field.label}</InputLabel>
        <FormControl size="small" fullWidth>
          <Select
            fullWidth
            size="small"
            style={{ textAlign: "left" }}
            variant="outlined"
            error={Boolean(error)}
            helperText={Boolean(error) && error}
            value={value}
            onChange={onChange}
          >
            {field?.options?.map((option) => (
              <MenuItem key={option.key} value={option.value}>
                {option.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    )}
  />
);

const RadioComponent = ({ field, control, error }) => (
  <Controller
    name={field.key}
    control={control}
    rules={field.validations}
    render={({ onChange, value }) => (
      <FormControl>
        <FormLabel id="demo-controlled-radio-buttons-group">
          {field.label}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={onChange}
        >
          {field?.options?.map((option) => (
            <FormControlLabel
              value={option.value}
              control={<Radio color="primary" />}
              label={option.key}
            />
          ))}
        </RadioGroup>
        {Boolean(error) && (
          <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>
        )}
      </FormControl>
    )}
  />
);

const CheckBoxComponent = ({ field, control, error }) => {
  const handleCheckboxChange = (optionKey, isChecked, value, onChange) => {
    let updatedItems;
    if (isChecked) {
      // Add the selected item to the array
      updatedItems = value?.length ? [...value, optionKey] : [optionKey];
    } else {
      // Remove the unselected item from the array
      updatedItems = value.filter((item) => item !== optionKey);
    }
    onChange(updatedItems);
  };

  return (
    <Controller
      name={field.key}
      control={control}
      rules={field.validations}
      render={({ onChange, value }) => (
        <FormControl>
          <FormLabel size="small">{field.label}</FormLabel>

          {field?.options?.map((option) => (
            <FormControlLabel
              key={option.key}
              checked={value?.length && value.includes(option.value)}
              control={<Checkbox color="primary" />}
              label={option.key}
              onChange={(e) =>
                handleCheckboxChange(
                  option.value,
                  e.target.checked,
                  value,
                  onChange
                )
              }
            />
          ))}

          {Boolean(error) && (
            <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

const FormFieldV2 = ({ field, userData }) => {
  //This context data from react-form-hook
  const {
    control,
    formState: { errors },
  } = useFormContext();

  switch (field.formField) {
    case "input":
    case "qrInput":
      return (
        <StringInput
          field={field}
          control={control}
          error={getErrorMessage(errors?.[field?.key]?.type)}
        />
      );
    case "date":
      return (
        <DateInput
          field={field}
          control={control}
          error={getErrorMessage(errors?.[field?.key]?.type)}
        />
      );
    case "dropDown":
      return (
        <FormSelect
          field={field}
          control={control}
          error={getErrorMessage(errors?.[field?.key]?.type)}
        />
      );
    case "radio":
      return (
        <RadioComponent
          field={field}
          control={control}
          error={getErrorMessage(errors?.[field?.key]?.type)}
        />
      );
    case "checkbox":
      return (
        <CheckBoxComponent
          field={field}
          control={control}
          error={getErrorMessage(errors?.[field?.key]?.type)}
        />
      );

    default:
      return <>to be rendered {field.formField}</>;
  }
};

export default memo(FormFieldV2);
