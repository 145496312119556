import { memo, useState, useCallback } from "react";
import { useForm, FormProvider } from "react-hook-form";
import FormField from "./FormField";
import { Button, DialogActions, Divider, Grid } from "@material-ui/core";
import FormFieldV2 from "./FormFieldV2";

const FormDetailsFrom = memo(
  ({
    formJson,
    resources,
    handleFormSubmit,
    handleFormCancel,
    saveButtonLabel,
    cancelButtonLabel,
    data,
    isEdit,
  }) => {
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm({
      mode: "onBlur",
      defaultValues: isEdit ? data : {},
    });
    const { handleSubmit, reset, setValue } = methods;

    // Form onsubmit method
    const onSubmit = async (data, e) => {
      try {
        await handleFormSubmit(data);
        resetDefaultValues();
        reset();
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const resetDefaultValues = useCallback(() => {
      const allFields = [];
      formJson?.form?.fields.forEach((field) => allFields.push(field.key));
      allFields.forEach((field) => {
        setValue(field, null);
      });
    }, [formJson?.form?.fields, setValue]);

    return (
      <div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid spacing={3} container>
              {formJson && !isLoading ? (
                formJson
                  .sort((a, b) => a.index - b.index)
                  .map((field, index) => (
                    <Grid
                      item
                      md={field?.form?.grid || 6}
                      sm={12}
                      key={`${field.key}-${index}`}
                    >
                      <FormFieldV2 field={field} setIsLoading={setIsLoading} />
                    </Grid>
                  ))
              ) : (
                <div>loading ...</div>
              )}
            </Grid>
            <Divider style={{ marginTop: "25px" }} />
            <DialogActions style={{ marginTop: "15px" }}>
              <Button onClick={handleFormCancel} color="primary">
                {cancelButtonLabel || "Cancel"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                autoFocus
                type="submit"
              >
                {saveButtonLabel || "Save Record"}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </div>
    );
  }
);

export default FormDetailsFrom;
