import { BASE_API, UploadHistoryEndPoints } from "../../../config";
import axios from "../../../utils/axios";

export default {
  getUploadHistory: async function (data) {
    try {
      let url = BASE_API + UploadHistoryEndPoints.uploadHistory;

      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteUploadHistory: async function (uploadId) {
    try {
      let url = BASE_API + UploadHistoryEndPoints.delete;
      url = url.replace("{id}", uploadId);

      const response = await axios.delete(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
