import React from "react";
import DashboardService from "../../project/services/Dashboard.service";
import LevelsIco from "../../../assets/images/levels.svg";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { DndProvider } from "react-dnd";
import { DragableLevels } from "../../project/DragableLevels";
import { CustomDragLayer } from "../../project/CustomDragLayer";
import createHTML5Backend from "react-dnd-html5-backend-cjs";
import { useEffect } from "react";
import { useState } from "react";
import { NoRecords } from "../../../components/NoRecords";
import { Charts } from "./Charts";

function BuildingTab({ selectedBuilding }) {
  const [levels, setLevels] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(true);

  useEffect(() => {
    selectedBuilding?.id && loadLevels();
  }, [selectedBuilding]);

  const loadLevels = async () => {
    try {
      let levelsData = await DashboardService.getLevelsBySelectedBuildings({
        buildingIds: [selectedBuilding.id],
      });
      setSelectedLevels(levelsData.map((x) => x.id));

      setLevels(levelsData);
    } catch (error) {
    } finally {
    }
  };

  const handleLevelChange = (levelId, index) => {
    const levelIndex = selectedLevels.indexOf(levelId);

    if (levelIndex === -1) {
      selectedLevels.push(levelId);
    } else {
      selectedLevels.splice(levelIndex, 1);
    }

    setSelectedLevels([...selectedLevels]);
    setIsAllSelected(selectedLevels.length === levels.length);
  };

  const handleAllSelected = () => {
    setIsAllSelected(!isAllSelected);
    setSelectedLevels(isAllSelected ? [] : levels.map((x) => x.id));
  };

  return (
    <>
      {levels.length ? (
        <>
          <div className="grid-filters">
            <div className="level-title">
              Levels
              <span>
                <img src={LevelsIco} /> {levels.length} Levels
              </span>
            </div>
            <div className="level-group">
              <ToggleButton
                onChange={() => handleAllSelected()}
                selected={isAllSelected}
                value="check"
              >
                <span>All</span>
              </ToggleButton>

              <DndProvider backend={createHTML5Backend}>
                <DragableLevels
                  {...{
                    buildingId: selectedBuilding.id,
                    levels,
                    setLevels,
                    onChange: handleLevelChange,
                    selectedLevels,
                  }}
                />
                <CustomDragLayer />
              </DndProvider>
            </div>
          </div>

          <Charts selectedLevels={selectedLevels} />
        </>
      ) : (
        <div className="tab-cnt">
          <NoRecords description="No levels available for this building" />
        </div>
      )}
    </>
  );
}

export default BuildingTab;
