import React from "react";

export const Legends = ({ values }) => {
  return (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        width: "100%",
        justifyContent: "center",
      }}
    >
      {values.map((x) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "25px",
          }}
        >
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: x.color,
              margin: 10,
              borderRadius: "20%",
            }}
          />
          {x.label}
        </div>
      ))}
    </div>
  );
};
