import React, { useEffect, useState } from "react";
import DashboardService from "./services/Dashboard.service";
import ProjectOverview from "./ProjectOverview";
import LineChart from "../../components/graphs/LineChart";
import { useProject } from "../../hooks/useProject";

const OverviewLayout = () => {
  const {
    selectedProject,
    fromDate,
    toDate,
    isDateFilterEnabled,
    selectedSubAssetTypes,
    selectedCategory,
    selectedAssetType,
  } = useProject();
  const [weekData, setWeekData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    selectedProject?.id &&
      selectedCategory?.id &&
      !isLoading &&
      selectedAssetType?.id &&
      loadWeeklyProgress();
  }, [
    selectedSubAssetTypes,
    fromDate,
    toDate,
    selectedCategory,
    selectedAssetType,
  ]);

  const loadWeeklyProgress = async () => {
    setIsLoading(true);
    try {
      let response = await DashboardService.getWeeklyProgress({
        projectId: selectedProject.id,
        assetTypeId: selectedAssetType?.id,
        fromDate: isDateFilterEnabled ? fromDate : null,
        toDate: isDateFilterEnabled ? toDate : null,
        assetCategoryId: selectedCategory?.id,
        subAssetTypeIds: selectedSubAssetTypes,
      });
      setIsLoading(false);
      if (response && response.length) {
        for (let i = 1; i < response.length; i++) {
          response[i].value += response[i - 1].value;
        }
        setWeekData(response);
      } else {
        setWeekData([]);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="charts">
        <ProjectOverview />
        <div id="chart">
          <LineChart data={weekData} />
        </div>
      </div>
    </div>
  );
};

export default OverviewLayout;
