// /* Imports */
import React, { useLayoutEffect, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);

am4core.useTheme(am4themes_animated);

export function PieChart({ data,index ,name}) {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    let chart = am4core.create(`chartdiv${index}`, am4charts.PieChart);

    // Add data
    chart.data = data;

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "status";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.labels.template.text = "";

    var title = chart.titles.create();
    title.text = name;
    title.fontSize = 12;
    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    chart.hiddenState.properties.radius = am4core.percent(0);

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div id={`chartdiv${index}`} style={{ width: "150px", height: "150px" }}></div>;
}

// export const PieChart = () => {
//   const series = [44, 55, 13, 43, 22];
//   const options = {
//     chart: {
//       width: 380,
//       type: "pie",
//     },
//     labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
//     responsive: [
//       {
//         breakpoint: 480,
//         options: {
//           chart: {
//             width: 200,
//           },
//           legend: {
//             position: "bottom",
//           },
//         },
//       },
//     ],
//   };
//   return (
//     <div id="chart">
//       <ReactApexChart
//         options={options}
//         series={series}
//         type="pie"
//         width={380}
//       />
//     </div>
//   );
// };
