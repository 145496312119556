import React, { useState, useEffect, useMemo } from "react";
import BuildingRadial from "../../components/graphs/BuildingRadial";
import DashboardService from "./services/Dashboard.service";
import moment from "moment";
import Overview from "../../assets/images/overview.svg";
import { useProject } from "../../hooks/useProject";
import { debounce, isEqual } from "lodash";

const ProjectOverview = (props) => {
  const {
    selectedProject,
    fromDate,
    toDate,
    isDateFilterEnabled,
    selectedSubAssetTypes,
    selectedCategory,
    selectedAssetType,
  } = useProject();

  const [overViewData, setOverViewData] = useState({
    buildings: [],
    totalCompleted: 0,
    totalAssets: 0,
  });

  const [dependency, setDependency] = useState({
    assetTypeId: selectedAssetType?.id,
    fromDate,
    toDate,
    projectId: selectedProject?.id,
    assetCategoryId: selectedCategory?.id,
    subAssetTypeIds: selectedSubAssetTypes,
  });

  const filterParams = useMemo(() => {
    return {
      assetTypeId: selectedAssetType?.id,
      fromDate,
      toDate,
      projectId: selectedProject?.id,
      assetCategoryId: selectedCategory?.id,
      subAssetTypeIds: selectedSubAssetTypes,
    };
  }, [
    selectedSubAssetTypes,
    fromDate,
    toDate,
    selectedProject,
    selectedCategory,
    selectedAssetType,
  ]);

  useEffect(() => {
    // Deep comparison using lodash isEqual function
    if (!isEqual(dependency, filterParams)) {
      setDependency(filterParams);
    }
  }, [
    selectedSubAssetTypes,
    fromDate,
    toDate,
    selectedProject,
    selectedCategory,
  ]);

  useEffect(() => {
    selectedProject?.id &&
      selectedCategory?.id &&
      selectedAssetType?.id &&
      loadOverView();
  }, [dependency]);

  const loadOverView = async () => {
    try {
      let response = await DashboardService.getOverviewProgress({
        projectId: selectedProject.id,
        assetTypeId: selectedAssetType?.id,
        fromDate: isDateFilterEnabled ? fromDate : null,
        toDate: isDateFilterEnabled ? toDate : null,
        assetCategoryId: selectedCategory?.id,
        subAssetTypeIds: selectedSubAssetTypes,
      });
      if (response) {
        setOverViewData({ ...response });
      } else {
        setOverViewData({ buildings: [], totalCompleted: 0, totalAssets: 0 });
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="ov-main">
        <div className="ov-main-l">
          <img width="60" height="60" src={Overview} />
          <div className="ov-desc">
            <span>Overall Completed Installations as at</span>
            <div>{moment().format("Do MMM YYYY")}</div>
          </div>
        </div>
        <div className="ov-main-r">
          <BuildingRadial
            key={overViewData.totalAssets}
            value={
              overViewData.totalCompleted > 0
                ? (overViewData.totalCompleted / overViewData.totalAssets) * 100
                : 0
            }
            displayJobDisplay={
              overViewData.totalCompleted + "/" + overViewData.totalAssets
            }
            size={500}
          />
        </div>
      </div>

      <div className="ov-sub">
        {overViewData.buildings.map((building, index) => {
          return (
            <div key={index} className="ov-b">
              <div className="ov-b-ttl">{building.building_name}</div>
              <div className="ov-b-stat">
                <BuildingRadial
                  key={building.total}
                  value={
                    building.completed > 0
                      ? (building.completed / building.total) * 100
                      : 0
                  }
                  displayJobDisplay={building.completed + "/" + building.total}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ProjectOverview;
