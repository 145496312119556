import React from "react";
import Header from "../../layout/Header";
import { ProjectContextProvider } from "../../context/projectContext";
import ClientList from "./ClientList";

const ClientHome = () => {
  return (
    <div className="wrapper">
      <ProjectContextProvider>
        <Header />
        <div className="content-wrapper">
          <div className="content">
            <ClientList />
          </div>
        </div>
      </ProjectContextProvider>
    </div>
  );
};

export default ClientHome;
