import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@material-ui/core";
import assetService from "../services/assetService";
import { FormInput } from "../../../components/FormInput";

const ResolveIssueModal = ({ open, onClose, onConfirm, assetId }) => {
  const [comment, setComment] = useState("");
  const [error, setError] = useState();

  useEffect(() => {
    setComment("");
    setError();
  }, [open]);

  const onSave = async () => {
    try {
      await assetService.addComment(assetId, {
        comment,
        commentType: "RESOLVE_COMMENT",
        hasIssues: false,
      });

      onConfirm(comment);
      onClose();
    } catch (error) {
      setError(error && error.message ? error.message : "Something went wrong");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">Resolve Issues</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Add resolve comment
        </DialogContentText>
        <FormInput
          error={error}
          hasError={Boolean(error)}
          value={comment}
          onChange={(value) => setComment(value)}
          label="Comment"
          fullWidth={true}
          multiline
          style={{ minWidth: 500 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!comment.length}
          onClick={() => onSave()}
          color="primary"
          autoFocus
        >
          Resolve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResolveIssueModal;
