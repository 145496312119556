import React, { useState, useEffect } from "react";
import OverviewLayout from "../../project/Overview";
import { Paper, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "./TabPanel";
import { useProject } from "../../../hooks/useProject";
import BuildingTab from "./BuildingTab";

const Dashboard = () => {
  const { projectBuildings, setProjectStatus, selectedBuildings } =
    useProject();
  const [selectedBuilding, setSelectedBuilding] = useState();

  const [selectedTab, setSelectedTab] = useState("overview");

  const handleTabChange = async (newValue) => {
    setSelectedTab(newValue);
    if (newValue == "overview") {
      setProjectStatus("all");
    } else if (projectBuildings[newValue]) {
      setSelectedBuilding(projectBuildings[newValue]);
    }
  };

  useEffect(() => {
    const currentBuilding = selectedBuildings.find(
      (x) => x === selectedBuilding?.id
    );

    if (!currentBuilding) {
      setSelectedBuilding();
      setSelectedTab("overview");
    }
  }, [selectedBuildings]);

  return (
    <>
      <Paper elevation={0}>
        <Tabs
          value={selectedTab}
          onChange={(e, value) => handleTabChange(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab value="overview" label="Overview" />
          {!!selectedBuildings.length &&
            selectedBuildings.map((buildingId, index) => (
              <Tab
                key={index}
                value={index}
                label={
                  projectBuildings.find((x) => x.id == buildingId)?.building
                }
              />
            ))}
        </Tabs>
      </Paper>
      <TabPanel value={selectedTab} show={selectedTab !== "overview"}>
        <BuildingTab selectedBuilding={selectedBuilding} />
      </TabPanel>
      <TabPanel value={selectedTab} index={"overview"}>
        <OverviewLayout />
      </TabPanel>
    </>
  );
};

export default Dashboard;
