import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from "@material-ui/core";
import React from "react";

const styles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(4),
  },
}));

export const CustomSelect = ({
  propertyName,
  options,
  label,
  value,
  error,
  hasError,
  valueKey,
  labelKey,
  onChange,
  disabled = false,
}) => {
  const classes = styles();
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={propertyName}
        label={label}
        error={hasError}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      >
        {options.length ? (
          options.map((option, i) => {
            return (
              <MenuItem key={i} value={option[valueKey]}>
                {option[labelKey]}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem disabled>No records</MenuItem>
        )}
      </Select>
      <FormHelperText error>{hasError && error}</FormHelperText>
    </FormControl>
  );
};
