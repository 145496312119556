import "./Login.scss";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import logo from "../../assets/images/logo-white.svg";
import ClientLogo from "../../assets/images/clientLogo.png"; 

// Project imports
import { CustomizedProgressBar } from "../../components/CircularProgress";

// Hooks imports
import useAuth from "../../hooks/useAuth";

const Login = () => {
  let history = useHistory();
  const { setUser, isAuthenticated, loginRequest } = useAuth();
  const { register, handleSubmit, errors } = useForm();
  const [loginError, setLoginError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/dashboard");
    }
  }, [isAuthenticated]);

  async function onSubmit(data, e) {
    try {
      e.preventDefault();
      setIsLoading(true);
      await handleLoginRequest(data);
      setIsLoading(false);
      history.push("/dashboard");
    } catch (error) {
      setLoginError(
        error?.mobile || !error?.message
          ? "Invalid credentials, Please try again..."
          : error?.message || "Something went wrong. Please contact the admin"
      );
      setIsLoading(false);
    }
  }

  const handleLoginRequest = async (data) => {
    try {
      let response = await loginRequest({
        username: data.username.trim(),
        password: data.password.trim(),
      });
      if (response && response.data) {
        return setUser(response.data, response.accessToken);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="side">
        <div>
          <img className="logo" src={logo} />
        </div>
      </div>
      <div className="login-form">
        <div className="form-group">
          {process.env.NODE_ENV === "production" && (
            <img className="logo-envar" src={ClientLogo} />
          )}
          <h2>Sign in to your Account</h2>
          <Fragment>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <span>
                <TextField
                  id="input-username"
                  label="User Name"
                  type="text"
                  name="username"
                  variant="outlined"
                  size="small"
                  error={errors && !!errors.username}
                  inputRef={register({
                    required: true,
                  })}
                  helperText={
                    errors && !!errors.username && "Enter your username"
                  }
                  onKeyDown={() => setLoginError()}
                />
                <p></p>
              </span>

              <span>
                <TextField
                  label="Password"
                  id="input-password"
                  defaultValue=""
                  variant="outlined"
                  size="small"
                  name="password"
                  type="password"
                  error={errors && !!errors.password}
                  inputRef={register({
                    required: true,
                  })}
                  onKeyDown={() => setLoginError()}
                  helperText={
                    errors && !!errors.password && "Enter your password"
                  }
                />
                <p></p>
              </span>

              {loginError && <span className="error-msg">{loginError}</span>}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                endIcon={isLoading && <CustomizedProgressBar />}
              >
                Sign in
              </Button>
            </form>
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default Login;
