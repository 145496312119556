import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React from "react";

export const AutoComplete = ({
  inputValue,
  value,
  handleChange,
  options,
  onSearch,
  label,
  optionLabel = null,
  disabled = false,
  size = "medium",
}) => {
  return (
    <Autocomplete
      id="combo-box-demo"
      inputValue={inputValue}
      value={value}
      size={size}
      onChange={(e, newValue) => handleChange && handleChange(newValue)}
      clearOnBlur={false}
      options={options}
      disabled={disabled}
      getOptionLabel={(option) =>
        optionLabel ? option[optionLabel] : option.name ?? ""
      }
      style={{ backgroundColor: "white", marginBottom: "20px" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          onChange={(event) => {
            onSearch && onSearch(event.target.value);
          }}
        />
      )}
    />
  );
};
