import React, { createContext, useEffect, useState } from "react";

// project import
import { AuthenticationEndPoints } from "../config";
import axiosServices from "../utils/axios";
import { USER_ROLE } from "../constants";
import UserService from "../pages/users/services/User.service";

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const getMyAccount = async () => {
    try {
      return await UserService.getUser();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        const user = await getMyAccount();
        setState({ isLoggedIn: true, isInitialized: true, user });
      } catch (err) {
        setState({ isLoggedIn: false, isInitialized: true, user: null });
      }
    };

    init();
  }, []);

  const setUser = (user, token) => {
    setState({ isLoggedIn: true, isInitialized: true, user });
    axiosServices.defaults.headers.common.authorization = `Bearer ${token}`;
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <>Loading...</>;
  }

  const loginRequest = async (data) => {
    try {
      return await UserService.login(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const logoutRequest = async () => {
    try {
      await axiosServices.get(AuthenticationEndPoints.logout);
      setState({ isLoggedIn: false, isInitialized: true, user: null });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <JWTContext.Provider
      value={{
        ...state,
        userRole: state?.user?.role?.slug,
        setUser,
        isAuthenticated: state.isLoggedIn,
        getMyAccount,
        loginRequest,
        logoutRequest,
        isAdmin: state?.user?.role?.slug === USER_ROLE.ADMIN,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
