import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import ProjectService from "../pages/project/services/Project.service";
import { AutoComplete } from "./AutoComplete";
import DashboardService from "../pages/project/services/Dashboard.service";
import { CircularProgress } from "@material-ui/core";
import clientService from "../pages/project/services/clientService";

const styles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dialogTitle: {
    paddingLeft: theme.spacing(4),
  },
  closeButton: {
    position: "absolute",
    right: "20px",
  },
  dialogContent: {
    padding: "32px 0 16px",
  },
  dialogActions: {
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
  margin: {
    marginRight: "12px",
  },
  stepperPadding: {
    padding: "8px 0 50px",
  },
  width: {
    width: "80%",
    margin: "0 auto 40px",
  },
}));

function getSteps() {
  return ["Enter Project Details", "Select file to upload", "Uploading status"];
}

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 2,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.primary[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 2,
    backgroundColor: "#5850eb",
  },
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const FileUploadProgress = (props) => {
  const classes = styles();
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.width}>
      <div className="file">
        <InsertDriveFileIcon className="file-icon" />
        <div className="file-content">
          <div className="file-name">
            {/* projectload-sheet.xlsx */}
            {props.fileName}
            {/*<CloseIcon className="file-remove"/>*/}
          </div>
          <LinearProgress />
        </div>
      </div>
    </div>
  );
};

const StatusMessages = () => {
  const classes = styles();

  return (
    <div className={classes.width}>
      <div className="upload-status-msg">
        <Alert severity="success">File uploaded successfully.!</Alert>
      </div>
    </div>
  );
};

const ErrorMessages = ({ errors }) => {
  const classes = styles();

  const createTextFile = () => {
    let sentence = "";

    if (Array.isArray(errors) && errors.length) {
      errors.forEach((text) => {
        sentence = `${sentence} Row ${text.row}:\n`;
        text.details.forEach((det) => (sentence = `${sentence}   - ${det} \n`));
        sentence = `${sentence} \n`;
      });
    }

    const element = document.createElement("a");
    const file = new Blob([sentence], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "error.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <div className={classes.width}>
      <div className="upload-status-msg">
        {errors && Array.isArray(errors) ? (
          <>
            <Alert severity="error">File upload failed!</Alert>
            <a onClick={createTextFile}>{"error.txt"}</a>
          </>
        ) : (
          <>
            <Alert severity="error">
              <p>File upload failed!</p>
              <p>{errors}</p>
            </Alert>
          </>
        )}
      </div>
    </div>
  );
};

let file = {};

const ProjectDetails = ({ onChange, projectDetails }) => {
  const classes = styles();
  const [clientList, setClientList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [clientListFetching, setClientListFetching] = useState(false);
  const [categoriesFetching, setCategoriesFetching] = useState(false);
  const [schemasFetching, setSchemasFetching] = useState(false);

  const handleOnChange = (name, value) => {
    onChange(name, value);
  };

  const getClients = useCallback(async () => {
    setClientListFetching(true);
    const clients = await clientService.getClients(true);
    setClientList(clients);
    setClientListFetching(false);
  }, []);

  const getCategories = useCallback(async () => {
    setCategoriesFetching(true);
    const categories = await ProjectService.getAllCategories();
    setCategories(categories);
    setCategoriesFetching(false);
  }, []);

  const getSchemas = useCallback(async () => {
    setSchemasFetching(true);
    const schemas = await ProjectService.getAllSchemas();
    setSchemas(schemas);
    setSchemasFetching(false);
  }, []);

  useEffect(() => {
    getClients();
    getCategories();
    getSchemas();
  }, []);

  return (
    <div className={classes.width}>
      {clientListFetching || categoriesFetching || schemasFetching ? (
        <Box textAlign={"center"}>
          <CircularProgress
            variant="indeterminate"
            disableShrink
            size={50}
            thickness={4}
          />
        </Box>
      ) : (
        <form>
          <FormGroup>
            <Box mb={2.5}>
              <TextField
                value={projectDetails?.projectName}
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                id="projectNameModal"
                label="Project name"
                variant="outlined"
                name="projectName"
                className={classes.root}
              />
            </Box>

            <AutoComplete
              handleChange={(e) => handleOnChange("clientId", e?.id)}
              options={clientList}
              label="Client"
              value={clientList.find(
                (opt) => opt.id === projectDetails?.clientId
              )}
            />

            <AutoComplete
              handleChange={(e) => handleOnChange("categoryId", e?.id)}
              options={categories}
              optionLabel="assetCategory"
              label="Category"
              value={categories.find(
                (opt) => opt.id === projectDetails?.categoryId
              )}
            />
            <AutoComplete
              handleChange={(e) => handleOnChange("schemaId", e?.id)}
              options={schemas}
              optionLabel="name"
              label="Schema"
              value={schemas.find((opt) => opt.id === projectDetails?.schemaId)}
            />
            {projectDetails.error && (
              <Alert severity="error">Please fill all the details</Alert>
            )}
          </FormGroup>
        </form>
      )}
    </div>
  );
};

const SelectFileToUpload = (props) => {
  const classes = styles();
  const [fileName, setFileName] = React.useState("");

  const uploadFile = () => {
    document.getElementById("selectExcelFile").click();
  };

  const onFileSelection = (event) => {
    props.handleFileSelect(event);
    setFileName(event.target.files[0].name);
  };

  return (
    <div className={classes.width}>
      {!fileName && (
        <div onClick={uploadFile} className="file-upload-btn">
          <CloudUploadIcon fontSize="large" className="file-upload-icon" />
          <div className="small-text">Click here to attached the file...</div>
          <input
            id="selectExcelFile"
            type="file"
            hidden
            onChange={(e) => onFileSelection(e)}
          />
        </div>
      )}

      {fileName && (
        <div onClick={uploadFile} className="file-upload-btn">
          <div className="small-text">{fileName}</div>
          <input
            id="selectExcelFile"
            type="file"
            hidden
            onChange={(e) => onFileSelection(e)}
          />
        </div>
      )}
    </div>
  );
};

let isSavingToServer = false;

export default function AddProjectModal({ handleUploadFinished }) {
  const classes = styles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [projectDetails, setProjectDetails] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [errors, setErrors] = React.useState([]);

  const handleClickOpen = () => {
    isSavingToServer = false;
    setOpen(true);
  };

  const handleClose = () => {
    setActiveStep(0);
    setOpen(false);
    handleUploadFinished(true);
    setProjectDetails("");
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (
        !projectDetails.projectName ||
        !projectDetails.clientId ||
        !projectDetails.categoryId
      ) {
        return setProjectDetails({ ...projectDetails, error: true });
      }
    }
    if (activeStep === 3 || activeStep === 4) {
      handleClose();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 3 || activeStep === 4) {
      setActiveStep(0);
      setOpen(false);
      handleUploadFinished(true);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const uploadFileToServer = async () => {
    try {
      isSavingToServer = true;

      let response = await ProjectService.uploadProjectCsvFile(
        projectDetails,
        file
      );
      if (response && response.data && response.success) {
        isSavingToServer = false;
        setActiveStep(3);
      }
    } catch (err) {
      isSavingToServer = false;
      if (err.error) {
        setErrors(err.error);
      } else {
        setErrors(err.message);
      }
      setActiveStep(4);
    }
  };

  const onChange = (name, value) =>
    setProjectDetails({ ...projectDetails, [name]: value, error: false });

  const handleFileSelect = (event) => {
    file = event.target.files[0];
    setFileName(file.name);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <ProjectDetails onChange={onChange} projectDetails={projectDetails} />
        );
      case 1:
        return (
          <SelectFileToUpload
            fileName={fileName}
            handleFileSelect={handleFileSelect}
          />
        );
      case 2:
        if (!isSavingToServer) {
          isSavingToServer = true;
          uploadFileToServer();
        }
        return <FileUploadProgress fileName={fileName} />;
      case 3:
        return <StatusMessages />;
      case 4:
        return <ErrorMessages errors={errors} />;
      default:
        return <StatusMessages />;
    }
  };

  return (
    <div>
      <Tooltip title="Upload Project Data">
        <Fab
          color="primary"
          aria-label="add record"
          className={classes.margin}
          size="medium"
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
      </Tooltip>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal"
      >
        <DialogTitle id="modal-title" className={classes.dialogTitle}>
          Upload Project Data
          {activeStep !== 2 && (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <div className={classes.root}>
            <Stepper
              className={classes.stepperPadding}
              activeStep={activeStep}
              alternativeLabel
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {/* {activeStep === steps.length ? (
                                <div>
                                    <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                                    <div className={classes.dialogActions}>
                                        <Button onClick={handleReset}>Reset</Button>
                                    </div>
                                </div>
                            ) : ( */}
              <div>
                <Typography className={classes.instructions}>
                  {getStepContent(activeStep)}
                </Typography>
                <div className={classes.dialogActions}>
                  {activeStep !== 3 && activeStep !== 4 && (
                    <Button
                      disabled={activeStep === 0 || activeStep === 2}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                  )}
                  {(activeStep === steps) != 2 && (
                    <Button
                      variant="contained"
                      disabled={activeStep === 2}
                      color="primary"
                      onClick={handleNext}
                    >
                      {activeStep === 3 || activeStep == 4 ? "Finish" : "Next"}
                    </Button>
                  )}
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
