import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import useAuth from "../hooks/useAuth";

const Header = () => {
  const { userRole, user, logoutRequest, isAdmin } = useAuth();

  return (
    <div className="header">
      <img src={logo} />
      <div className="header-right-blc">
        <div className="nav">
          <NavLink to="/dashboard">Home</NavLink>
          {isAdmin && (
            <>
              <NavLink to="/user">Users</NavLink>
              <NavLink to="/client">Clients</NavLink>
              <NavLink to="/projects">Project Upload</NavLink>
            </>
          )}
        </div>
        <div className="user-profile">
          <PersonOutlineIcon color="primary" />
          <div className="user-name">
            {user.firstName}
            <a className="log-out" onClick={logoutRequest}>
              LOGOUT
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
