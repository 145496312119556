import React from "react";
import ReactApexCharts from "react-apexcharts";

const BuildingRadial = ({ size = 192, value = 0, displayJobDisplay }) => {
  const color =
    value >= 75
      ? "#00D66F"
      : value < 75 && value >= 35
      ? "#e5e84f"
      : value < 35 && value > 0
      ? "#e84f5e"
      : "#E5EBF5";

  const overViewOptions = {
    chart: {
      height: size,
      width: size,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        track: {
          background: "#E1E7F3",
        },
        dataLabels: {
          name: {
            fontSize: "20px",
            color: "#3F3F46",
            offsetY: -10,
          },
          value: {
            offsetY: 0,
            fontSize: "12px",
            fontWeight: "600",
            color: "#6C7280",
            formatter: function (val) {
              return `${displayJobDisplay} Completed`;
            },
          },
        },
        hollow: {
          size: "64%",
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
    colors: [color],
    labels: [`${value ? value.toFixed(2) : 0}%`],
  };

  return (
    <div>
      <ReactApexCharts
        options={overViewOptions}
        series={[value]}
        type="radialBar"
        width={215}
        height={240}
      />
    </div>
  );
};

export default BuildingRadial;
