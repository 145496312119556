import {
  Avatar,
  Chip,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
  lighten,
  makeStyles,
} from "@material-ui/core";
import { Fragment } from "react";
import { LevelFilter } from "../../../components/LevelFilter";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { useProject } from "../../../hooks/useProject";
import {
  downloadAssets,
  downloadAssetsExcel,
} from "../../downloads/download.service";
import clsx from "clsx";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  searchFilter: {
    flex: "1 1 0",
  },
  search: {
    padding: "0 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    marginRight: "10px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  margin: {
    marginRight: "16px",
  },
}));

export const EnhancedTableToolbar = ({
  numSelected,
  levels,
  onLevelChange,
  selectedLevels,
  onAddNew,
  selectedRowIds,
  filteredAssetIds,
  onSetOpenDeletePasswordConfirmation,
  issuesCount,
  onToggle,
  showIssues,
}) => {
  const classes = useToolbarStyles();

  const {
    selectedProject,
    searchPenetration,
    setSearchPenetration,
    projectStatus,
    setProjectStatus,
    selectedCategory,
  } = useProject();

  const downloadExcel = async () => {
    let stringIdList = null;

    selectedRowIds.length
      ? selectedRowIds.forEach((assetId) => {
          if (stringIdList) stringIdList = `${stringIdList},${assetId}`;
          else stringIdList = assetId;
        })
      : filteredAssetIds.forEach((assetId) => {
          if (stringIdList) stringIdList = `${stringIdList},${assetId}`;
          else stringIdList = assetId;
        });

    downloadAssetsExcel({
      projectId: selectedProject.id,
      assetIds: selectedRowIds,
      categoryId: selectedCategory.id,
    });
  };

  const downloadPdf = async () => {
    downloadAssets({
      penRecIds: selectedRowIds,
      projectName: selectedProject.projectName,
    });
  };
  const deleteList = () => {
    onSetOpenDeletePasswordConfirmation(true);
  };

  return (
    <Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <div style={{ display: "flex", flex: "1 1 0" }}>
            <Paper
              component="form"
              className={classes.search}
              style={{ border: "1px solid #d6d7d9", boxShadow: "none" }}
            >
              <IconButton
                className={`${classes.iconButton} live_seach_icon`}
                aria-label="search"
              >
                <SearchIcon fontSize="24px" />
              </IconButton>
              <Divider className={classes.divider} orientation="vertical" />
              <InputBase
                className={classes.input}
                placeholder="Search by Asset ID..."
                value={searchPenetration}
                fullWidth
                onChange={(e) => setSearchPenetration(e.target.value)}
              />
            </Paper>

            <LevelFilter
              options={levels}
              onChange={onLevelChange}
              selectedItems={selectedLevels}
            />
            <div className="filter">
              <ToggleButtonGroup
                className="filter-status"
                value={projectStatus}
                exclusive
                onChange={(e, value) => setProjectStatus(value)}
                aria-label="text alignment"
                size="small"
              >
                <ToggleButton value="all">All</ToggleButton>
                <ToggleButton value="pending">Pending</ToggleButton>
                <ToggleButton value="in progress">In Progress</ToggleButton>
                <ToggleButton value="completed">Completed</ToggleButton>
              </ToggleButtonGroup>
            </div>
            {!!issuesCount && (
              <div className="filter ">
                <Chip
                  variant="outlined"
                  color={showIssues ? "secondary" : "default"}
                  size="large"
                  label="Issues"
                  avatar={<Avatar>{issuesCount}</Avatar>}
                  onClick={onToggle}
                />
              </div>
            )}
          </div>
        )}
        {numSelected > 0 ? (
          <>
            {/* <Tooltip title="Download Excel"> //todo hiding this - will be implemented in next phase
              <IconButton
                onClick={() => downloadExcel()}
                aria-label="download excel"
              >
                <LibraryBooksIcon fontSize="default" />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Download PDF">
              <IconButton onClick={() => downloadPdf()}>
                <PictureAsPdfIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={() => deleteList()} aria-label="delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Fragment>
            {/* <Tooltip title="Download Excel">
              <IconButton
                onClick={() => downloadExcel()}
                aria-label="download excel"
              >
                <LibraryBooksIcon fontSize="default" />
              </IconButton>
            </Tooltip> */}
          </Fragment>
        )}
      </Toolbar>
    </Fragment>
  );
};
