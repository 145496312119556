import { ClientEndpoints, DashboardEndPoints } from "../../../config";
import axios from "../../../utils/axios";

const getDashboardData = async (projectId) => {
  try {
    let url = DashboardEndPoints.dashboard;
    url = url.replace("{projectId}", projectId);

    const response = await axios.get(url);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getDashboardDataPenoStatusCount = async (body) => {
  try {
    let url = DashboardEndPoints.assetStatusSummary;

    const response = await axios.post(url, body);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const reOrderLevel = async (data) => {
  try {
    let url = DashboardEndPoints.reorderLevels;

    const response = await axios.put(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getOverviewProgress = async (body) => {
  try {
    let url = DashboardEndPoints.overviewProgress;

    const response = await axios.post(url, body);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
const getWeeklyProgress = async (body) => {
  try {
    let url = DashboardEndPoints.weeklyProgress;

    const response = await axios.post(url, body);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getLevelsBySelectedBuildings = async (body) => {
  try {
    let url = DashboardEndPoints.buildingLevels;

    const response = await axios.post(url, body);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default {
  getDashboardData,
  getDashboardDataPenoStatusCount,
  reOrderLevel,
  getOverviewProgress,
  getWeeklyProgress,
  getLevelsBySelectedBuildings,
};
