import React from "react";
import { FormInput } from "../../../components/FormInput";
import { FormGroup } from "@material-ui/core";
import { useState } from "react";
import { validate } from "validate.js";
import { FormDialog } from "../../../components/FormDialog";
import clientService from "../../project/services/clientService";
import { useEffect } from "react";

const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const initValues = {
  name: "",
};

export const ClientForm = ({
  open,
  setOpen,
  onSuccess,
  onError,
  setIsLoading,
  selectedClient,
}) => {
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({ ...initValues });

  useEffect(() => {
    setErrors({});
    setTouched({});
    setValues({ ...initValues });
  }, [open]);

  useEffect(() => {
    selectedClient?.id
      ? setValues({ name: selectedClient.name })
      : setValues({ ...initValues });
  }, [selectedClient]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      setTouched({
        name: true,
      });
      if (validateForm(values)) {
        selectedClient?.id
          ? await clientService.updateClient(selectedClient.id, values)
          : await clientService.createClient(values);

        onSuccess();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.status == 409) {
        setErrors({ ...errors, name: ["Client name already used"] });
      } else {
        onError(error);
      }
    }
  };

  const validateForm = (values) => {
    const errors = validate(values, schema);

    setErrors({ ...errors });
    return !errors ? true : false;
  };

  const hasError = (field) => (touched[field] && errors[field] ? true : false);

  const onChange = (name, value) => {
    const newValues = {
      ...values,
      [name]: value,
    };
    setValues(newValues);
    validateForm(newValues);
    setTouched({ ...touched, [name]: true });
  };

  return (
    <FormDialog
      title="Add new client"
      open={open}
      handleClose={() => setOpen(false)}
      handleSave={handleSave}
    >
      <form>
        <FormGroup>
          <FormInput
            error={hasError("name") && errors.name[0]}
            hasError={hasError("name")}
            value={values.name}
            onChange={(value) => onChange("name", value)}
            label="Client Name"
          />
        </FormGroup>
      </form>
    </FormDialog>
  );
};
