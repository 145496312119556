import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AddUserModal from "../../components/AddUser";
import LockIcon from "@material-ui/icons/Lock";

import UserService from "./services/User.service";

import UserPasswordModal from "../../components/UserPasswordModal";
import { LinearProgress, Snackbar } from "@material-ui/core";
import { Alert, AlertTitle, ToggleButton } from "@material-ui/lab";
import DeleteConfirm from "../project/DeleteConfirm";
import { ConfirmDialog } from "../../components/ConfirmDialog";

const headCells = [
  { id: "name", numeric: false, disablePadding: false, label: "Name" },
  { id: "userName", numeric: false, disablePadding: false, label: "Username" },
  { id: "role", numeric: false, disablePadding: false, label: "Role" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "action", numeric: true, disablePadding: false, label: "" },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  toolBar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: "1 1 100%",
  },
  search: {
    padding: "0 4px",
    display: "flex",
    alignItems: "center",
    width: 260,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  iconButton: {
    padding: 10,
  },
  tableWidth: {
    width: "75%",
    margin: "50px auto 0",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  margin: {
    marginRight: theme.spacing(1),
  },
  buttonBack: {
    marginBottom: theme.spacing(2),
  },
  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const EnhancedTableToolbar = ({ loadUsers }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Toolbar className={classes.toolBar}>
        <div className={classes.title}>
          <h3>Users</h3>
        </div>
        <AddUserModal loadUsers={() => loadUsers()} />
      </Toolbar>
    </Fragment>
  );
};

export default function UserPage() {
  const classes = useStyles();
  const [selected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [openDisableUserConfirmation, setOpenDisableUserConfirmation] =
    useState(false);

  const [selectedUser, setSelectedUser] = useState("");
  const childModalRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);

  const [alertData, setAlertData] = useState({
    title: "success",
    type: "success",
    message: "success",
  });

  useEffect(() => {
    loadUsers();
  }, [rowsPerPage, page]);

  const loadUsers = useCallback(async () => {
    let data = {
      pageLimit: rowsPerPage,
      currentPage: page + 1,
    };

    try {
      setIsLoading(true);
      let { users, totalCount } = await UserService.getUsersPaginated(data);

      setUsers(users);
      setTotalCount(totalCount);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const disableUserById = async () => {
    try {
      setIsLoading(true);
      let response = await UserService.blockUser(
        selectedUser.id,
        !selectedUser.activeStatus
      );
      if (response && response.data && response.success) {
        setOpenDisableUserConfirmation(false);
        loadUsers();
        return response.data;
      }
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteUserById = async () => {
    try {
      setIsLoading(true);
      let response = await UserService.deleteUserById(selectedUser.id);
      if (response) {
        loadUsers();
        setSnackBarOpen(true);
        setAlertData({
          title: "Success",
          type: "success",
          message: `Successfully deleted the user`,
        });
        return response.data;
      }
    } catch (error) {
      setSnackBarOpen(true);
      setAlertData({
        title: "Error",
        type: "error",
        message: error.message || "Something went wrong",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = (user) => {
    if (childModalRef && childModalRef.current) {
      setSelectedUser(user);
      childModalRef.current.handleClickOpen();
    }
  };

  return (
    <div className={classes.tableWidth}>
      <Paper className={classes.paper} elevation={0}>
        <EnhancedTableToolbar
          loadUsers={loadUsers}
          numSelected={selected.length}
        />
        {isLoading ? (
          <LinearProgress
            className={classes.loadingBar + " loading-bar"}
            color="primary"
          />
        ) : (
          <TableContainer>
            <Table size="small">
              <EnhancedTableHead classes={classes} />
              <TableBody>
                {users.map((user, index) => {
                  return (
                    <TableRow hover>
                      <TableCell component="th">
                        {user.firstName} {user.lastName}
                      </TableCell>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>{user.role.name}</TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            user.activeStatus ? "Disable User" : "Enable User"
                          }
                        >
                          <ToggleButton
                            value="check"
                            size="small"
                            style={{
                              padding: "3px 7px",
                              fontSize: "0.7125rem",
                              minWidth: "75px",
                              backgroundColor: user.activeStatus
                                ? "#25d6221f"
                                : "#d622221f",
                            }}
                            selected={selected}
                            onChange={(e) => {
                              e.preventDefault();
                              setSelectedUser(user);
                              setOpenDisableUserConfirmation(true);
                            }}
                          >
                            {user.activeStatus ? "Active" : "Inactive"}
                          </ToggleButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Reset Password">
                          <IconButton
                            onClick={(e) => handleResetPassword(user)}
                            aria-label="reset password"
                            className={classes.margin}
                            size="small"
                          >
                            <LockIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete User">
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedUser(user);
                              setOpenDeleteConfirmation(true);
                            }}
                            aria-label="delete user"
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <UserPasswordModal
        loadUsers={(e) => loadUsers()}
        userId={selectedUser.id}
        ref={childModalRef}
      />

      {/* Disable user confirmation */}
      <ConfirmDialog
        open={openDisableUserConfirmation}
        title={selectedUser.activeStatus ? "Disable User" : "Unblock User"}
        onClose={() => setOpenDisableUserConfirmation(false)}
        description={
          !selectedUser.activeStatus
            ? "Are you sure that you want to unblock user"
            : "Are you sure that you want to disable the user?"
        }
        onConfirm={disableUserById}
      />

      <Snackbar
        open={snackBarOpen}
        autoHideDuration={1000}
        onClose={() => setSnackBarOpen(false)}
      >
        <Alert
          onClick={() => setSnackBarOpen(false)}
          severity={alertData.type}
          variant="filled"
        >
          <AlertTitle>{alertData.title}</AlertTitle>
          {alertData.message}
        </Alert>
      </Snackbar>

      <DeleteConfirm
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        onConfirm={deleteUserById}
        title="Confirm Delete"
        description="Are you sure that you want to delete the user"
      />
    </div>
  );
}
