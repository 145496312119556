import React, { useState, useEffect, useMemo } from "react";
import { Box, Switch, TextField } from "@material-ui/core";
import moment from "moment";
import { CustomDatePicker } from "./DatePicker";
import DashboardService from "../pages/project/services/Dashboard.service";
import { MenuSelector } from "./MenuSelector";
import { useProject } from "../hooks/useProject";

const dateRanges = [
  { key: "today", label: "Today" },
  { key: "yesterday", label: "Yesterday" },
  { key: "thisWeek", label: "This Week" },
  { key: "lastWeek", label: "Last Week" },
  { key: "lastTwoWeeks", label: "last Two Weeks" },
  { key: "thisMonth", label: "This Month" },
  { key: "lastMonth", label: "Last Month" },
  { key: "lastTwoMonths", label: "Last Two Months" },
  { key: "lastThreeMonths", label: "Last Three Months" },
  { key: "custom", label: "Custom Range" },
];

export const DateFilter = ({ disabled = false }) => {
  const {
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    isDateFilterEnabled,
    setIsDateFilterEnabled,
    selectedProject,
  } = useProject();
  const [durationValue, setDurationValue] = useState(0);
  const [isCustomDate, setIsCustomDate] = useState(false);
  const [minDate, setMinDate] = useState(moment().startOf("day"));

  useEffect(() => {
    if (selectedProject?.id) {
      setMinDate(moment(selectedProject?.projectStartedDate).startOf("day"));
    }
  }, [selectedProject]);

  useEffect(() => {
    handleFromDateChange(minDate);
  }, [minDate]);

  useEffect(() => {
    !isCustomDate &&
      setDurationValue(moment(toDate).diff(moment(fromDate), "days") + 1);
  }, [fromDate, toDate]);

  const validDateError = !moment(fromDate).isSameOrBefore(moment(toDate))
    ? "From Date cannot be greater than To Date"
    : // : !moment(minDate).isSameOrBefore(moment(fromDate))
      // ? "Date should not be before project start date" //commenting out to enable date for demo
      "";

  const handleToDateChange = (date) => {
    var end = moment(date);
    setToDate(end.endOf("day"));
    setDurationValue(end.diff(moment(fromDate), "days"));
  };

  const handleFromDateChange = (date) => {
    var start = moment(date);
    setFromDate(start.startOf("day"));
    setDurationValue(toDate.diff(moment(start), "days"));
  };

  const handleDurationChange = (value) => {
    let duration = value;
    setDurationValue(duration);
    let end = moment(toDate);
    const start = end.add(-duration, "days");
    setFromDate(start.startOf("day"));
  };

  const onRangeSelect = (key) => {
    key !== "custom" && setIsCustomDate(false);
    switch (key) {
      case "today":
        handleFromDateChange(moment());
        handleToDateChange(moment());
        break;
      case "yesterday":
        handleFromDateChange(moment().add(-1, "day"));
        handleToDateChange(moment().add(-1, "day"));
        break;
      case "thisWeek":
        handleFromDateChange(moment().startOf("isoWeek"));
        handleToDateChange(moment());
        break;
      case "lastWeek":
        handleFromDateChange(moment().add(-1, "week").startOf("isoWeek"));
        handleToDateChange(moment().add(-1, "week").endOf("isoWeek"));
        break;
      case "lastTwoWeeks":
        handleFromDateChange(moment().add(-1, "week").startOf("isoWeek"));
        handleToDateChange(moment());
        break;
      case "thisMonth":
        handleFromDateChange(moment().startOf("month"));
        handleToDateChange(moment());
        break;
      case "lastMonth":
        handleFromDateChange(moment().add(-1, "month").startOf("month"));
        handleToDateChange(moment().add(-1, "month").endOf("month"));
        break;
      case "lastTwoMonths":
        handleFromDateChange(moment().add(-1, "month").startOf("month"));
        handleToDateChange(moment());
        break;
      case "lastThreeMonths":
        handleFromDateChange(moment().add(-2, "month").startOf("month"));
        handleToDateChange(moment());
        break;
      case "custom":
        setIsCustomDate(true);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div>
        <label style={{ fontWeight: "bold" }}>Apply Date Range</label>
        <Switch
          checked={isDateFilterEnabled}
          disabled={disabled}
          onChange={() => setIsDateFilterEnabled(!isDateFilterEnabled)}
          name="checkedB"
          color="primary"
        />
      </div>
      <Box style={{ border: "1px solid rgba(0, 0, 0, 0.12)", padding: "24px" }}>
        <div className="input-wrapper">
          <div style={{ display: "flex", alignItems: "center" }}>
            <MenuSelector
              items={dateRanges}
              onSelect={onRangeSelect}
              disabled={!isDateFilterEnabled || disabled}
            />
            <TextField
              label="Duration"
              id="outlined-size-small"
              onChange={(e) => handleDurationChange(e.target.value)}
              value={durationValue}
              disabled={!isDateFilterEnabled || !isCustomDate}
              variant="outlined"
              type="number"
              size="small"
              style={{ backgroundColor: "white" }}
            />
          </div>
        </div>
        <div>
          <CustomDatePicker
            disabled={!isDateFilterEnabled}
            label="From"
            error={validDateError}
            helperText={validDateError}
            value={fromDate}
            onChange={handleFromDateChange}
            // minDate={minDate}  //commenting out to enable date for demo
            maxDate={moment()}
          />
          <CustomDatePicker
            disabled={!isDateFilterEnabled}
            label="To"
            value={toDate}
            onChange={handleToDateChange}
            minDate={minDate}
            maxDate={moment()}
          />
        </div>
      </Box>
    </>
  );
};
