import {
  Fab,
  LinearProgress,
  Paper,
  Snackbar,
  Toolbar,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import useAuth from "../../hooks/useAuth";
import { Alert, AlertTitle } from "@material-ui/lab";
import DeleteConfirm from "../project/DeleteConfirm";
import clientService from "../project/services/clientService";
import { ClientTable } from "./components/ClientTable";
import { ClientForm } from "./components/ClientForm";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { useProject } from "../../hooks/useProject";

const useStyles = makeStyles((theme) => ({
  toolBar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: "1 1 100%",
  },
  tableWidth: {
    width: "75%",
    margin: "50px auto 0",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const ClientList = ({}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientsList, setClientsList] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const { isAdmin } = useAuth();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  const [changeStatusConfirmation, setChangeStatusConfirmation] =
    React.useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const {
    selectedClient,
    resetFilters,
    setSelectedProject,
    setSelectedClient,
  } = useProject();

  const [alertData, setAlertData] = useState({
    title: "success",
    type: "success",
    message: "success",
  });

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    setIsLoading(true);
    const clients = await clientService.getClients();
    setClientsList(clients);
    setIsLoading(false);
  };

  const onSuccess = () => {
    setAlertData({
      title: "Success",
      type: "success",
      message: `Successfully ${
        selectedRecord?.id ? "updated" : "created"
      } the client`,
    });
    setSnackBarOpen(true);
    setOpen(false);
    getClients();
    setSelectedRecord();
  };

  const onError = (error) => {
    setAlertData({
      title: "Error",
      type: "error",
      message: error.message || "Something went wrong",
    });
    setSnackBarOpen(true);
    setOpen(false);
    setSelectedRecord();
  };

  const rowActions = [
    {
      callback: (row, index) => {
        setOpen(true);
        setSelectedRecord({ ...row });
      },
      icon: <EditIcon />,
      disabled: () => !isAdmin,
    },
    {
      callback: (row, index) => {
        setSelectedRecord(row);
        setOpenDeleteConfirmation(true);
      },
      icon: <DeleteIcon />,
      disabled: (row) => row.projectCount > 0 || !isAdmin,
    },
  ];

  const deleteClient = async () => {
    try {
      setIsLoading(true);
      let response = await clientService.deleteClient(selectedRecord.id);

      if (response) {
        if (selectedRecord.id == selectedClient.id) {
          resetClient();
        }
        getClients();
        setSnackBarOpen(true);
        setAlertData({
          title: "Success",
          type: "success",
          message: `Successfully deleted the client`,
        });
        return response.data;
      }
    } catch (error) {
      setSnackBarOpen(true);
      setAlertData({
        title: "Error",
        type: "error",
        message: error.message || "Something went wrong",
      });
    } finally {
      setIsLoading(false);
      setSelectedRecord();
    }
  };

  const onChangeStatus = (client) => {
    setSelectedRecord(client);
    setChangeStatusConfirmation(true);
  };

  const changeStatus = async () => {
    try {
      setIsLoading(true);
      await clientService.updateClient(selectedRecord.id, {
        isActive: !selectedRecord.isActive,
      });
      getClients();
      if (selectedRecord.isActive && selectedRecord.id == selectedClient.id) {
        resetClient();
      }
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const resetClient = () => {
    resetFilters();
    setSelectedProject(null);
    setSelectedClient(null);
  };

  return (
    <div className={classes.tableWidth}>
      <Paper className={classes.paper} elevation={0}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.title}>
            <h3>Clients</h3>
          </div>
          <div>
            <Tooltip title="Add new client">
              <Fab
                color="primary"
                aria-label="add record"
                className={classes.margin}
                size="medium"
                onClick={() => setOpen(true)}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </div>
        </Toolbar>
        {isLoading ? (
          <LinearProgress className={"loading-bar"} color="primary" />
        ) : (
          <ClientTable
            {...{
              clientsList,
              rowActions,
              onChangeStatus,
            }}
          />
        )}

        <ClientForm
          {...{
            onSuccess,
            onError,
            open,
            setIsLoading,
            setOpen,
            selectedClient: selectedRecord,
          }}
        />
      </Paper>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={1000}
        onClose={() => setSnackBarOpen(false)}
      >
        <Alert
          onClick={() => setSnackBarOpen(false)}
          severity={alertData.type}
          variant="filled"
        >
          <AlertTitle>{alertData.title}</AlertTitle>
          {alertData.message}
        </Alert>
      </Snackbar>
      <DeleteConfirm
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        onConfirm={deleteClient}
        title="Confirm Delete"
        description="Are you sure that you want to delete the client"
      />
      <ConfirmDialog
        open={changeStatusConfirmation}
        title={selectedRecord?.isActive ? "Disable Client" : "Unblock Client"}
        onClose={() => setChangeStatusConfirmation(false)}
        description={
          !selectedRecord?.isActive
            ? "Are you sure that you want to unblock client"
            : "Are you sure that you want to disable the client?"
        }
        onConfirm={changeStatus}
      />
    </div>
  );
};

export default ClientList;
