import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LevelsIco from "../../assets/images/levels.svg";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Toolbar from "@material-ui/core/Toolbar";
import { downloadAssets, getBuildingLevels } from "./download.service";
import ProjectService from "../../pages/project/services/Project.service";
import { useProject } from "../../hooks/useProject";
import DashboardService from "../project/services/Dashboard.service";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    flexDirection: "row",
    justifyContent: "space-between",
  },
  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function DownloadLayout() {
  const {
    selectedProject,
    fromDate,
    toDate,
    projectAssetTypes,
    projectBuildings,
    selectedCategory,
    isDateFilterEnabled,
  } = useProject();
  const classes = useStyles();
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedSubAssetTypes, setSelectedSubAssetTypes] = useState([]);
  const [levels, setLevels] = useState();

  useEffect(() => {
    projectBuildings.length && getLevelsByBuildingId();
  }, [projectBuildings]);

  const getLevelsByBuildingId = async () => {
    let levelsData = await DashboardService.getLevelsBySelectedBuildings({
      buildingIds: projectBuildings.map((b) => b.id),
    });
    setLevels(levelsData);
    setSelectedLevels(levelsData.map((lvl) => lvl.id));
  };

  const handleAssetSelect = (e) => {
    debugger;
    setSelectedSubAssetTypes(
      e.target.checked
        ? [...selectedSubAssetTypes, +e.target.name]
        : selectedSubAssetTypes.filter(
            (assetType) => assetType !== e.target.name
          )
    );
  };

  const downloadPdf = async () => {
    downloadAssets({
      levels: selectedLevels,
      assetTypeId: selectedSubAssetTypes,
      fromDate: isDateFilterEnabled ? fromDate : null,
      toDate: isDateFilterEnabled ? toDate : null,
      projectName: selectedProject.projectName,
      categoryId: selectedCategory.id,
    });
  };

  return (
    <div>
      <Toolbar className={classes.root}>
        <RadioGroup row aria-label="gender" name="gender1">
          {projectAssetTypes &&
            projectAssetTypes.map((value) => (
              <FormControlLabel
                value={value.id}
                control={
                  <Checkbox
                    color="primary"
                    name={value.id}
                    onChange={handleAssetSelect}
                  />
                }
                label={value.assetType}
              />
            ))}
        </RadioGroup>
        <Tooltip title="Download PDF">
          <IconButton
            disabled={!selectedLevels.length}
            onClick={() => downloadPdf()}
          >
            <PictureAsPdfIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <Fragment>
        {levels &&
          projectBuildings?.map((building) => (
            <BuildingSection
              {...{
                selectedLevels,
                setSelectedLevels,
                building,
                levels: levels.filter((lvl) => lvl.buildingId === building.id),
              }}
            />
          ))}
      </Fragment>
    </div>
  );
}

const BuildingSection = ({
  building,
  selectedLevels,
  setSelectedLevels,
  levels,
}) => {
  const [isAllSelected, setIsAllSelected] = useState(true);

  const onSelectLevel = (levelId) => {
    const levelIndex = selectedLevels.indexOf(levelId);

    if (levelIndex === -1) {
      selectedLevels.push(levelId);
    } else {
      selectedLevels.splice(levelIndex, 1);
    }

    setSelectedLevels([...selectedLevels]);
    setIsAllSelected(
      selectedLevels.filter((lvl) => levels.some((l) => l.id === lvl))
        .length === levels.length
    );
  };

  const onSelectAll = (e) => {
    setIsAllSelected(!isAllSelected);
    setSelectedLevels(
      isAllSelected
        ? selectedLevels.filter((lvl) => !levels.some((l) => l.id === lvl))
        : [...new Set([...selectedLevels, ...levels.map((x) => x.id)])]
    );
  };

  return (
    <div className="grid-filters">
      <div className="levels">
        <div className="building-title">{building.building}</div>
        <div className="level-title">
          Levels
          <span>
            <img src={LevelsIco} alt="NoImage" /> {levels?.length}
          </span>
        </div>
        <div className="level-group">
          <ToggleButton
            value="check"
            onChange={onSelectAll}
            selected={isAllSelected}
          >
            <span>all</span>
          </ToggleButton>
          {levels.map((level) => (
            <ToggleButton
              value="check"
              onChange={(e) => onSelectLevel(level.id)}
              selected={selectedLevels.some((x) => x === level.id)}
            >
              <span>{level.level}</span>
            </ToggleButton>
          ))}
        </div>
      </div>
    </div>
  );
};
