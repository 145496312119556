import React from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export const CustomDatePicker = ({ value, onChange, label, ...props }) => {
  return (
    <div className="input-wrapper">
      <label>{label}</label>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          {...props}
          disableToolbar
          autoOk={true}
          margin="normal"
          id="date-picker-to-dialog"
          format="dd/MM/yyyy"
          value={value}
          variant="inline"
          size="small"
          onChange={onChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};


