import React, { useEffect, Fragment, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import DeleteIcon from "@material-ui/icons/Delete";
import ProjectService from "./services/Project.service";
import EditIcon from "@material-ui/icons/Edit";
import AddEditPenetrationModal from "../../components/AddEditPenetrationModal";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DoneIcon from "@material-ui/icons/Done";
import { Alert, AlertTitle } from "@material-ui/lab";
import moment from "moment";
import "./Project.scss";
import DashboardService from "./services/Dashboard.service";
import { downloadAssets } from "../downloads/download.service";
import { LevelFilter } from "../../components/LevelFilter";
import DeleteConfirm from "./DeleteConfirm";
import { useProject } from "../../hooks/useProject";
import useAuth from "../../hooks/useAuth";
import { useCallback } from "react";
import assetService from "./services/assetService";
import { Paper, Snackbar, TablePagination } from "@material-ui/core";
import CollapsibleTable from "../../components/CollapsibleTable/CollapsibleTable";
import { EnhancedTableToolbar } from "./components/EnhancedToolbar";
import ResolveIssueModal from "./components/ResolveIssueModal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  margin: {
    marginRight: theme.spacing(1),
  },
  loadingBar: {
    position: "absolute",
  },
  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  status: {
    padding: "3px 7px",
    textTransform: "uppercase",
    fontSize: "0.7125rem",
    fontWeight: "500",
    minWidth: "75px",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "bold",
    borderRadius: "4px",
    "&.completed": {
      backgroundColor: "#25d6221f",
      border: "1px solid #3aa01980",
    },
    "&.pending": {
      backgroundColor: "#eceff1",
      border: "1px solid #90a4ae",
    },
    "&.inprogress": {
      backgroundColor: "#ffad141f",
      border: "1px solid #cd7e0880",
    },
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const {
    selectedProject,
    projectStatus,
    fromDate,
    toDate,
    searchPenetration,
    selectedSubAssetTypes,
    selectedProjectPenetration,
    setSelectedProjectPenetration,
    selectedBuildings,
    selectedCategory,
    isDateFilterEnabled,
    selectedAssetType,
    subAssetTypes,
  } = useProject();

  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [totalPenetrationCount, setTotalPenetrationCount] = React.useState(10);

  const childModalRef = useRef();
  const [penetrationsData, setPenetrationsData] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editEnable, seteditEnable] = useState(false);
  const [levels, setLevels] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [filteredAssetIds, setFilteredAssetIds] = useState([]);
  const [openDeletePasswordConfirmation, setOpenDeletePasswordConfirmation] =
    React.useState(false);
  const [showIssues, setShowIssues] = useState(false);
  const [issuesCount, setIssuesCount] = useState(0);
  const [selectedAssetId, setSelectedAssetId] = useState();

  const { isAdmin } = useAuth();

  const headCells = [
    {
      id: "assetId",
      align: "left",
      disablePadding: true,
      label: "Asset ID",
    },
    {
      id: "subAssetType",
      align: "left",
      disablePadding: false,
      label: "Sub Asset Type",
    },
    {
      id: "building",
      align: "left",
      disablePadding: false,
      label: "Building",
    },
    {
      id: "level",
      align: "left",
      disablePadding: false,
      label: "Level",
    },
    {
      id: "status",
      align: "left",
      disablePadding: false,
      label: "Status",
      getClassName: (row) =>
        `${classes.status} ${row.status.replace(/\s/g, "")}`,
    },
    {
      id: "date",
      align: "left",
      disablePadding: false,
      label: "Completed date",
      format: (row) =>
        row.status === "completed"
          ? moment(row.date).format("DD/MM/yyyy hh:mm A")
          : "N/A",
    },
  ];

  const onLevelChange = (selectedOption) => setSelectedLevels(selectedOption);

  const [debounceTimer, setDebounceTimer] = useState(null);

  useEffect(() => {
    // Clear the timeout when the component unmounts
    return () => clearTimeout(debounceTimer);
  }, [debounceTimer]);

  const [alertData, setAlertData] = useState({
    title: "success",
    type: "success",
    message: "success",
  });

  const loadData = useCallback(async () => {
    setIsLoading(true);
    clearTimeout(debounceTimer);
    let data = {
      pageLimit: rowsPerPage,
      currentPage: page + 1,
      fromDate: isDateFilterEnabled ? fromDate : null,
      toDate: isDateFilterEnabled ? toDate : null,
      searchText: searchPenetration,
      status: projectStatus,
      buildings: selectedBuildings,
      assetTypeId: selectedAssetType?.id,
      selectedLevels: selectedLevels,
      projectId: selectedProject.id,
      assetCategoryId: selectedCategory?.id,
      hasIssues: !!showIssues || undefined,
      subAssetTypeIds: selectedSubAssetTypes,
    };
    setDebounceTimer(
      setTimeout(async () => {
        let responseData = await loadPenetrations(data);

        let assets = responseData.assets;
        setPenetrationsData(assets);
        setTotalPenetrationCount(responseData.totalCount);
        setFilteredAssetIds(responseData.filteredAssetIds);
        setIssuesCount(responseData.issues);
        setRows([]);
        if (assets.length) {
          const rows = assets.map((asset, index) => {
            const {
              id,
              assetId,
              assetType,
              building,
              level,
              status,
              statusUpdatedDate,
              roomName,
              roomNumber,
              drawingId,
              otherDetails,
              formDetails,
              assetImages,
              hasIssues,
              subAssetTypeId,
              subAssetType,
              ...rest
            } = asset;

            return {
              labelId: `enhanced-table-checkbox-${index}`,
              id: id,
              assetId: assetId,
              assetCode: assetType?.assetType,
              building: building?.building,
              level: level?.level,
              status: status,
              date: statusUpdatedDate,
              otherDetails: {
                roomName: roomName,
                roomNumber: roomNumber,
                drawingId: drawingId,
                ...otherDetails,
              },
              formDetails: formDetails,
              images: assetImages,
              hasIssues: hasIssues,
              subAssetTypeId: subAssetTypeId,
              subAssetType: subAssetType?.subAssetType,
              schema:
                subAssetTypes.find((x) => x.id == asset.subAssetTypeId)
                  ?.schema ?? {},
              ...rest,
            };
          });
          setRows(rows);
        }
        setIsLoading(false);
      }, 500)
    );
  }, [
    selectedProject,
    page,
    fromDate,
    toDate,
    searchPenetration,
    projectStatus,
    selectedBuildings,
    selectedSubAssetTypes,
    selectedLevels,
    selectedCategory,
    showIssues,
    rowsPerPage,
    selectedAssetType,
    selectedSubAssetTypes,
  ]);

  useEffect(() => {
    if (
      selectedProject?.id &&
      selectedCategory?.id &&
      selectedAssetType?.id &&
      subAssetTypes.length
    ) {
      loadData();
    } else {
      setSelected([]);
    }
  }, []);

  useEffect(() => {
    loadLevels();
  }, [selectedBuildings]);

  const loadLevels = async () => {
    const levelData = await DashboardService.getLevelsBySelectedBuildings({
      buildingIds: selectedBuildings,
    });
    setLevels(levelData);

    setSelectedLevels(levelData.map((x) => x.id));
  };

  useEffect(() => {
    if (selectedProject?.id && selectedCategory?.id) {
      loadData();
    } else {
      setSelected([]);
    }
  }, [
    projectStatus,
    selectedProject,
    searchPenetration,
    fromDate,
    toDate,
    page,
    rowsPerPage,
    selectedSubAssetTypes,
    selectedLevels,
    selectedBuildings,
    selectedCategory,
    showIssues,
  ]);

  useEffect(() => {
    setShowIssues(false);
  }, [
    projectStatus,
    selectedProject,
    searchPenetration,
    fromDate,
    toDate,
    selectedSubAssetTypes,
    selectedLevels,
    selectedBuildings,
    selectedCategory,
  ]);

  useEffect(() => {
    if (!selectedProject || !selectedProject.id) {
      setRows([]);
      setSelected([]);
    }
    setPage(0);
  }, [searchPenetration]);

  const loadPenetrations = async (data) => {
    try {
      setIsLoading(true);
      return await ProjectService.getPenetrations(data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected([...new Set([...selected, ...newSelecteds])]);
      return;
    } else setSelected([]);
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setSelected([]);
    setPage(0);
  };

  const handlePenetrationEdit = (asset, formType) => {
    seteditEnable(true);
    const selectedAsset = penetrationsData.find((x) => x.id == asset.id);
    setSelectedProjectPenetration(selectedAsset);
    if (childModalRef && childModalRef.current) {
      setSelectedProjectPenetration(selectedAsset);
      childModalRef.current.handleClickOpen(formType);
    }
  };

  const handlePenetrationAdd = (e) => {
    seteditEnable(false);

    setSelectedProjectPenetration({});

    if (childModalRef && childModalRef.current) {
      setSelectedProjectPenetration({});
      childModalRef.current.handleClickOpen();
    }
  };

  const deletePenetration = (asset) => {
    setSelectedProjectPenetration(asset);
    setOpenDeleteConfirmation(true);
  };

  const deletePenetrationsByIdList = async () => {
    try {
      setIsLoading(true);
      let response = await assetService.deletePenetrationList(selected);
      if (response && response.data && response.success) {
        setOpenDeletePasswordConfirmation(false);
        setAlertData({
          title: "Success",
          type: "success",
          message: "Successfully deleted the asset List",
        });
        setOpenSnackBar(true);
        loadData(selectedProject.id, true);
        setSelected([]);
        return response.data;
      }
    } catch (error) {
      setAlertData({
        title: "Error",
        type: "error",
        message:
          error.data && error.data.message
            ? error.data.message
            : "Something went wrong",
      });
      setOpenSnackBar(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onResolveIssue = async () => {
    if (issuesCount === 1) setShowIssues(false);
    setAlertData({
      title: "Success",
      type: "success",
      message: "Successfully resolved the issue",
    });
    setOpenSnackBar(true);
  };

  const deletePenetrationById = async () => {
    try {
      setIsLoading(true);
      let response = await assetService.deletePenetrationList([
        selectedProjectPenetration.id,
      ]);
      if (response && response.data && response.success) {
        setAlertData({
          title: "Success",
          message: "Successfully deleted the asset",
          type: "success",
        });
        setOpenDeleteConfirmation(false);
        setOpenSnackBar(true);
        loadData(selectedProject.id, true);
      }
    } catch (error) {
      setAlertData({
        title: "Error",
        message: "could not delete the asset",
        type: "error",
      });
      setOpenSnackBar(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleDeleteConfirmationClose = (event) => {
    setOpenDeleteConfirmation(false);
  };

  const downloadPdf = (row) => {
    downloadAssets({
      penRecIds: [row.id],
      projectName: selectedProject.projectName,
    });
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const rowActions = [
    {
      tooltip: "Download pdf",
      callback: (row) => downloadPdf(row),
      icon: <PictureAsPdfIcon />,
      disabled: (row) => row.status !== "completed",
    },
    {
      tooltip: "Edit record",
      callback: (row, index) => handlePenetrationEdit(row, "otherDetails"),
      icon: <EditIcon />,
      disabled: () => !isAdmin,
    },
    {
      tooltip: "Delete record",
      callback: (row, index) => deletePenetration(row),
      icon: <DeleteIcon />,
      disabled: () => !isAdmin,
    },
    {
      tooltip: "Resolve Issue",
      callback: (row) => setSelectedAssetId(row.id),
      icon: <DoneIcon />,
      hide: (row) => !row.hasIssues,
    },
  ];

  const onIssueFilterChange = () => {
    setShowIssues(!showIssues);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          onAddNew={() => handlePenetrationAdd()}
          selectedRowIds={selected}
          onSetOpenDeletePasswordConfirmation={
            setOpenDeletePasswordConfirmation
          }
          {...{
            levels,
            onLevelChange,
            selectedLevels,
            filteredAssetIds,
            onToggle: onIssueFilterChange,
            issuesCount,
            showIssues,
          }}
        />
        <DeleteConfirm
          open={openDeletePasswordConfirmation}
          onClose={() => setOpenDeletePasswordConfirmation(false)}
          onConfirm={deletePenetrationsByIdList}
          title="Delete records"
          description="Are you sure that you want to delete selected records? "
        />

        <>
          <CollapsibleTable
            isLoading={isLoading}
            headCells={headCells}
            checked={rows.length > 0 && selected.length === rows.length}
            indeterminate={selected.length > 0 && selected.length < rows.length}
            onSelectAll={handleSelectAllClick}
            onSelect={handleClick}
            rows={rows}
            selectedRecords={selected}
            rowActions={rowActions}
            onEdit={handlePenetrationEdit}
            loadData={loadData}
          />
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalPenetrationCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          {selectedProject?.id && selectedCategory?.id && (
            <AddEditPenetrationModal
              loadPenetrations={loadData}
              pentrationData={selectedProjectPenetration}
              ref={childModalRef}
              canEdit={editEnable}
              levels={levels}
            />
          )}
        </>
      </Paper>

      <Snackbar
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertData.type} variant="filled">
          <AlertTitle>{alertData.title}</AlertTitle>
          {alertData.message}
        </Alert>
      </Snackbar>
      <DeleteConfirm
        open={openDeleteConfirmation}
        onClose={handleDeleteConfirmationClose}
        onConfirm={deletePenetrationById}
        title="Delete record"
        description="Are you sure that you want to delete the asset?"
      />
      <ResolveIssueModal
        open={!!selectedAssetId}
        onClose={() => setSelectedAssetId(false)}
        assetId={selectedAssetId}
        onConfirm={onResolveIssue}
      />
    </div>
  );
}
