import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  chipsSection: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  pieChartArea: {
    flexWrap: "wrap",
    padding: "40px",
    margin: "auto",
    display: "flex",
    minHeight: "400px",
  },
  loadingDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
