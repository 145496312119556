import {
  Box,
  Button,
  Collapse,
  Divider,
  Paper,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import ImageViewer from "../ImageViewer";
import { useState } from "react";
import { TabPanel } from "../../pages/dashboard/components/TabPanel";
import { NoRecords } from "../NoRecords";
import EditIcon from "@material-ui/icons/Edit";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  otherDetails: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    "& > p": {
      margin: "0.5em 0",
      "& > strong": {
        fontWeight: 500,
      },
    },
  },
});
export const ExtraDetailsRow = ({ row, headCells, open, onEdit, loadData }) => {
  const classes = useRowStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <TableRow style={{ backgroundColor: "#f8f8ff8f" }}>
      <TableCell style={{ padding: 0 }} colSpan={headCells.length + 3}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Paper elevation={0} className="top-tab">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => setSelectedTab(newValue)}
              indicatorColor="primary"
              textColor="primary"
              style={{ maxWidth: 500 }}
              centered
            >
              <Tab label="Asset Details" />
              <Tab label="Form details" />
              <Tab label="Images" />
            </Tabs>
          </Paper>

          <Box margin={1} padding={2} minHeight={220}>
            <TabContent {...{ selectedTab, row, classes, onEdit, loadData }} />
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export const TabContent = React.memo(
  ({ selectedTab, row, classes, onEdit, loadData }) => {
    const { schema, otherDetails, formDetails, assetImages } = row;
    const { excelSchema, formSchema, imageSchema } = schema;

    const EditRecord = ({ onClick }) => {
      return (
        <>
          <Button
            onClick={() => onClick()}
            variant="outlined"
            size="medium"
            color="default"
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
          <Divider style={{ marginTop: "5px" }} />
        </>
      );
    };
    console.log("row", row);
    const { areaName, installedBy, areaServed, siteName } = row;
    const assetInfo = {
      areaName,
      installedBy,
      areaServed,
      siteName,
      ...otherDetails,
    };
    return (
      <>
        <TabPanel value={selectedTab} index={0}>
          <EditRecord onClick={() => onEdit(row, "otherDetails")} />
          <span className={classes.otherDetails}>
            {}
            {Object.keys(assetInfo).map(
              (property) =>
                excelSchema?.find((x) => x.key == property) && (
                  <p>
                    <strong>{`${
                      excelSchema.find((x) => x.key == property).label
                    }: `}</strong>
                    {assetInfo?.[property]}
                  </p>
                )
            )}
          </span>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <>
            <EditRecord onClick={() => onEdit(row, "formDetails")} />
            {formDetails ? (
              <span className={classes.otherDetails}>
                {Object.keys(formDetails).map(
                  (property) =>
                    formSchema?.find((x) => x.key == property) && (
                      <p>
                        <strong>{`${
                          formSchema.find((x) => x.key == property).label
                        }: `}</strong>
                        {Array.isArray(formDetails?.[property])
                          ? formDetails?.[property].join(", ")
                          : formDetails?.[property]}
                      </p>
                    )
                )}
              </span>
            ) : (
              <NoRecords
                message="No Data to Display"
                showDescription={false}
                showImage={false}
              />
            )}
          </>
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <>
            <ImageViewer
              assetId={row.id}
              images={row.images}
              loadData={loadData}
              imageSchema={imageSchema}
            />
          </>
        </TabPanel>
      </>
    );
  }
);
